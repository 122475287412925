export default {

    /**
     * 是否中国手机号码
     */
    isChinaMobile(value: string = ''): boolean {
        return value.length === 11 && /^1\d{10}$/.test(value);
    },
    /**
     * 是否座机号码
     */
    isSeatNo(value: string = ''): boolean {
        return /^0\d{2,3}-?\d{7,8}$/.test(value);
    },
    /**
     * 是否有效网址
     */
    isUrl(value: string = ''): boolean {
        return /(http|https):\/\/[\w\-_]+(\.[\w\-_]+)+([\w\-\.,@?^=%&:/~\+#]*[\w\-\@?^=%&/~\+#])?/i.test(value);
    },


    /**
     * 是否合法验证码
     * @param value
     * @param len - 验证码长度
     */
    isCode(value: string = '', len = 4): boolean {
        return value.length === len && /^\d*$/.test(value);
    },
    /**
     * 是否为汉字且不为空
     * @param value
     * @returns {boolean}
     */
    isChinese(value: string = ''): boolean {
        return /^[\u0391-\uFFE5]+$/.test(value);
    },

    /**
     * 判断是否为整数
     * @param value
     * @returns {boolean}
     */
    isInt(value: string = ''): boolean {
        return /^[-+]?\d*$/.test(value);
    },
    /**
     * 判断是否为整数
     * @param value
     * @returns {boolean}
     */
    isNatural(value: string = ''): boolean {
        return /^[+]?\d*$/.test(value) && parseInt(value) > 0;
    },


    /**
     * 是否安全
     * @param value
     * @returns {boolean}
     */
    isSafe(value: string = ''): boolean {
        if (!value) {
            return true;
        }
        return !/['"/\\<>]/gi.test(value);
    },
    /**
     * 是否安全
     * @param value
     * @returns {boolean}
     */
    isAccount(value: string = ''): boolean {
        if (!value) {
            return true;
        }
        return !/^[a-zA-Z0-9_]+$/.test(value);
    },

    /**
     * 仅允许中文、数字、字母（禁止字符、换行等）
     * @param value
     * @returns {boolean}
     */
    isNotSymbol(value: string = ''): boolean {
        if (!value) {
            return true;
        }
        return /^[A-Za-z0-9\u4e00-\u9fa5]+$/gi.test(value);
    },
    /**
     * 判断是否合法IP地址
     * @param val
     * @returns {boolean}
     */
    isIp(val: string = ''): boolean {
        return /^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/.test(val);
    },

    /**
     * 是否中国身份证号码
     * @param code
     * @returns {boolean}
     */
    idCardNo(code: string = ''): boolean {

        return /^\d{6}(18|19|20)?\d{2}(0[1-9]|1[012])(0[1-9]|[12]\d|3[01])\d{3}(\d|X)$/i.test(code);
    },
};
