/**
 * 数组排序之升序排列
 * @param {Array} arr 数组
 * @param {String|null} key - string，可选。当数据item为对象的时候，指定排序的属性名
 * @returns {Array}
 * @constructor
 */
export function ascOrder(arr: any[], key: string = ''): any[] {
    if (key.length > 0) {
        arr.sort(function (b, a) {
            return b[key] - a[key];
        });
    } else {
        arr.sort(function (b, a) {
            return b - a;
        });
    }
    return arr;
}

/**
 * 数组排序之降序排列
 * @param {Array} arr 数组
 * @param {String|null} key - string，可选。当数据item为对象的时候，指定排序的属性名
 * @returns {Array}
 * @constructor
 */
export function descOrder(arr: any[], key: string = ''): any[] {
    if (key.length > 0) {
        arr.sort(function (b, a) {
            return a[key] - b[key];
        });
    } else {
        arr.sort(function (b, a) {
            return a - b;
        });
    }
    return arr;
}

