import Check from "@/utils/validate/check";
import { layer } from "@/utils";

interface Rule {
  rule: string;
  value: string;
  key: string;
  label: string;
  _fun?: any;
  reg?: any;
  errMsg: string | undefined;
}

/**
 * 表单校验
 * @param model - 数据
 * @param config - 校验规则
 * @param showError - 显示错误
 */
function checkModel(model: any, config?: any, showError?: Function) {
  config = config || model.Rule;

  const rules: Rule[] = [];

  //region 将config转为rule数组
  for (const key in config) {
    if (config.hasOwnProperty(key)) {
      const value = model[key];
      const aRule = config[key];
      const label = aRule.label;
      const aRules = aRule.rule.split(",");
      aRules.forEach(function(rule: string) {
        rules.push({
          key,
          label,
          value,
          rule,
          _fun: aRule.fun,
          reg: aRule.reg,
          errMsg: aRule.errMsg
        });
      });
    }
  }
  //endregion

  const len = rules.length;
  let success = true,
    i = 0,
    item: Rule,
    errMsg = "";

  //region 循环校验

  while (success && i < len) {
    item = rules[i];
    switch (item.rule) {
      case "notEmpty":
      case "必填":
        if (item.value.length === 0) {
          onError(item.errMsg || `请输入${item.label}`);
        }
        break;
      case "mobile":
      case "手机号码":
        if (!Check.isChinaMobile(item.value)) {
          onError(item.errMsg || `${item.label}不是有效的手机号码`);
        }
        break;
      case "int":
        if (!Check.isInt(item.value)) {
          onError(item.errMsg || `${item.label}仅允许输入整数`);
        }
        break;
      case "safe":
        if (!Check.isSafe(item.value)) {
          onError(item.errMsg || `${item.label}禁止输入特殊符号`);
        }
        break;
      case "noSymbol":
        if (!Check.isNotSymbol(item.value)) {
          onError(item.errMsg || `${item.label}只允许输入汉字、数字和字母`);
        }
        break;
      case "chinese":
      case "cn":
        if (!Check.isChinese(item.value)) {
          onError(item.errMsg || `${item.label}仅允许输入汉字`);
        }
        break;
      case "account":
        if (!Check.isAccount(item.value)) {
          onError(item.errMsg || `${item.label}仅允许输入数字和字母和下划线`);
        }
        break;
      case "natural":
        if (!Check.isNatural(item.value)) {
          onError(item.errMsg || `${item.label}仅允许输入大于0的整数`);
        }
        break;
      case "custom":
        if (!item.reg.test(item.value)) {
          onError(item.errMsg || `${item.label}校验失败`);
        }
        break;
      case "function":
        if (!item._fun(item.value)) {
          onError(item.errMsg || `${item.label}校验失败`);
        }
        break;
      default:
        //其他 进行长度校验
        regular(item);
        break;
    }
    i++;
    console.log(item.key, item.rule, success);
  }
  //endregion

  if (success) {
    return Promise.resolve(model);
  } else {
    return Promise.reject();
  }

  function onError(msg: string) {
    success = false;
    errMsg = msg;
    if (showError) {
      showError(msg);
    } else {
      layer.error(msg);
    }
  }

  /**
   * 正则表达式校验
   */
  function regular(item: Rule) {
    const minReg = /^min\[(\d+)\]/;
    const maxReg = /^max\[(\d+)\]/;
    const lenReg = /^len\[(\d+)-(\d+)\]/;
    const sameReg = /^same\[([a-zA-Z0-9_]+)\]/;
    let success = true;

    if (minReg.test(item.rule)) {
      //最小长度校验
      (function() {
        const result: string[] = item.rule.match(minReg) || [];
        const min = parseInt(result[1]);
        if (item.value.length < min) {
          onError(item.errMsg || `${item.label}不能低于${min}个字符`);
        }
      })();
    } else if (maxReg.test(item.rule)) {
      //最大长度校验
      (function() {
        const result: string[] = item.rule.match(maxReg) || [];
        const max = parseInt(result[1]);
        if (item.value.length > max) {
          onError(item.errMsg || `${item.label}不能超过${max}个字符`);
        }
      })();
    } else if (lenReg.test(item.rule)) {
      //同时限制最大和最小长度
      (function() {
        const result: string[] = item.rule.match(lenReg) || [];
        const min = parseInt(result[1]);
        const max = parseInt(result[2]);
        success = item.value.length >= min && item.value.length <= max;
        if (!success) {
          onError(
            item.errMsg || `${item.label}长度不能小于${min}，也不能大于${max}`
          );
        }
      })();
    } else if (sameReg.test(item.rule)) {
      //密码输入不一致校验
      (function() {
        const result: string[] = item.rule.match(sameReg) || [];
        success = model[result[1]] === item.value;
        if (!success) {
          onError(item.errMsg || `${item.label}输入不一致`);
        }
      })();
    } else {
      console.log("未定义的校验规则", item.rule);
    }
  }
}

export { checkModel };
