const contentType = {
  get: undefined,
  post: "application/x-www-form-urlencoded; charset=UTF-8",
  postJson: "application/json; charset=UTF-8",
  file: "multipart/form-data"
};
import { layer } from "../layer";
import { AppConfig } from "@/app-config";
import Axios from "axios";
import qs from "qs";
import { Global } from "../global";

interface AjaxHeader {
  token: string;
}

class AjaxModel {
  header: AjaxHeader;
  loadingNum: number;
  _lock: boolean;

  constructor() {
    this.header = {
      token: ""
    }; //	该对象下的所有数据均会添加到Ajax请求头中。主要用于发送token
    this.loadingNum = 0; //正在进行中的启用了加载动画的Ajax请求数量
    this._lock = false;
  }

  jsonp(url: string, data: any = {}, callback: Function) {
    const script = document.createElement("script");
    script.setAttribute("type", "text/javascript");
    let callbackFun: string = `callback${Global.newId()}`;
    url = url + "?callback=" + callbackFun;
    Object.keys(data).forEach(function(key) {
      url += `&${key}=${data[key]}`;
    });
    script.src = url;
    document.body.appendChild(script);

    // @ts-ignore
    window[callbackFun] = function(data: any) {
      callback(data);
      // @ts-ignore
      delete window[callbackFun];
    };
  }

  /**
   * 自动转化短路径为长路径
   * @param api
   */
  apiFilter(api = ""): string {
    if (api.indexOf("//") !== 0 && api.indexOf("/") === 0) {
      return AppConfig.apiUrl(api);
    }
    return api;
  }

  /**
   * 创建请求头
   * @param type
   * @returns {{"X-Requested-With": string, "Content-Type": *}}
   */
  newHeader(type: "get" | "post" | "postJson" | "file" = "get") {
    let data: any = {
      "X-Requested-With": "XMLHttpRequest",
      "Content-Type": contentType[type]
    };
    if (type === "get" && this.header.token) {
      data = {
        token: this.header.token
      };
    }
    Object.assign(data, this.header);
    return data;
  }

  /**
   * 防止重复提交
   * @returns {Promise<void>}
   */
  whileUnlock(): Promise<void> {
    if (this._lock) {
      return Promise.reject();
    } else {
      this._lock = true;
      return Promise.resolve();
    }
  }

  /**
   * 解除锁定
   */
  unlock(): void {
    this._lock = false;
  }

  /**
   * 服务端出错时触发
   * @returns {Promise<void>}
   */
  onServerError(err: any): Promise<void> {
    console.log("err", err);
    return Promise.reject("服务器未响应，或接口地址有误");
  }

  /**
   * 出错时触发
   * @param msg
   */
  onError(msg: string = ""): void {
    layer.error(msg);
  }

  /**
   * 请求开始前触发
   */
  loadingStart(): void {
    //console.warn("请求开始");
    this.loadingNum++;
    this.onLoadingShow();
  }

  /**
   * 请求结束时触发
   */
  loadingStop(): void {
    //console.warn("请求结束");
    this.loadingNum--;
    if (this.loadingNum === 0) {
      this.onLoadingHide();
    }
  }

  /**
   * 需要显示加载动画时触发
   */
  onLoadingShow(): void {
    layer.loading();
  }

  /**
   * 需要停止加载动画时触发
   */
  onLoadingHide(): void {
    layer.hideLoading();
  }

  /**
   * 请求结束后触发
   * @param result
   * @returns {Promise<any>}
   */
  afterRequest(result: object) {
    const res = this.filter(result);
    return new Promise((resolve, reject) => {
      if (res.success || res.code === 0 || res.code === 200) {
        resolve(res);
      } else {
        this.onError(res.msg);
        reject(res.msg);
      }
    });
  }

  /**
   * 请求结束后触发 用于对数据进行统一的预处理
   * @param result
   * @returns {*}
   */
  filter(result: any): any {
    return result;
  }

  /**
   * 请求开始前触发 用于为所有请求添加统一的数据
   * @param data
   * @returns {*}
   */
  everyPara(data = {}) {
    return data;
  }

  createData(
    url: string,
    params = {},
    type: "get" | "post" | "postJson" | "file" = "get"
  ): object {
    let postData: any = {
      url: this.apiFilter(url),
      timeout: 30000,
      headers: this.newHeader(type),
      method: type
    };

    switch (type) {
      case "get":
        postData.params = params;
        break;
      case "post":
        postData.data = qs.stringify(params);
        break;
      case "postJson":
        postData.data = params;
        postData.method = "post";
        break;
    }
    return postData;
  }

  /**
   * get方式提交数据
   * @param url
   * @param data
   * @param showLoading
   * @param useFilter
   */
  get(
    url: string = "",
    data = {},
    showLoading = true,
    useFilter = true
  ): Promise<any> {
    if (url.length === 0) {
      throw "Ajax请求地址不能为空";
    }
    this.everyPara(data);
    if (showLoading) {
      this.loadingStart();
    }
    return Axios(this.createData(url, data, "get"))
      .then(res => {
        if (showLoading) {
          this.loadingStop();
        }
        if (useFilter) {
          return this.afterRequest(res.data);
        } else {
          return Promise.resolve(res.data);
        }
      })
      .catch(res => {
        if (showLoading) {
          this.loadingStop();
        }
        if (AppConfig.debug) {
          layer.alert(url, "接口请求失败");
        }
      });
  }

  /**
   * post form方式提交数据
   * @param url
   * @param data
   * @param showLoading
   * @param useFilter
   */
  post(
    url: string,
    data = {},
    showLoading = true,
    useFilter = true
  ): Promise<any> {
    if (url.length === 0) {
      throw "Ajax请求地址不能为空";
    }

    this.everyPara(data);
    if (showLoading) {
      this.loadingStart();
    }

    return Axios(this.createData(url, data, "post")).then(res => {
      console.warn("接口请求结果", res.data);
      if (showLoading) {
        this.loadingStop();
      }
      if (useFilter) {
        return this.afterRequest(res.data);
      } else {
        return Promise.resolve(res.data);
      }
    });
  }

  /**
   * post方式提交json数据
   * @param url
   * @param data
   * @param showLoading
   * @param useFilter
   */
  postJson(
    url: string,
    data = {},
    showLoading = true,
    useFilter = true
  ): Promise<any> {
    if (url.length === 0) {
      throw "Ajax请求地址不能为空";
    }
    this.everyPara(data);
    if (showLoading) {
      this.loadingStart();
    }
    return Axios(this.createData(url, data, "postJson")).then(res => {
      console.warn("接口请求结果", res.data);
      if (showLoading) {
        this.loadingStop();
      }
      if (useFilter) {
        return this.afterRequest(res.data);
      } else {
        return Promise.resolve(res.data);
      }
    });
  }

  /**
   * get方式获取文本数据
   * @param url
   * @param paras
   * @param showLoading
   */
  getText(url: string, paras = {}, showLoading = true): Promise<any> {
    this.everyPara(paras);
    return this.get(url, paras, showLoading, false);
  }

  /**
   * 获取分页数据 默认为get方式
   * @param url
   * @param page
   * @param opt
   * @param mode
   * @returns {PromiseLike<T | never> | Promise<T | never>}
   */
  getPage(
    url: string,
    page: number = 1,
    opt: any,
    mode: "get" | "post" = "get"
  ) {
    opt = Object.assign(
      {
        paras: {},
        limit: 20,
        onEnd: function(isEmpty: boolean) {},
        filter: function(result: any) {
          return result;
        }
      },
      opt || {}
    );
    const paras = Object.assign(this.buildPagePara(page, opt.limit), opt.paras);

    return this[mode](url, paras).then(function(result) {
      if (!result) {
        opt.onEnd(page === 1);
        return {
          total: 0,
          data: []
        };
      }
      result = opt.filter(result);
      result.total = parseInt(result.total); //后台给出的可能是字符串
      result.data = result.data || [];
      if (result.data.length < opt.limit) {
        opt.onEnd(page === 1);
      }
      return result;
    });
  }

  /**
   * 构造分页参数
   * @param page
   * @param limit
   * @returns {{offset: number, limit: *}}
   */
  buildPagePara(page: number, limit: number) {
    return {
      limit,
      offset: (page - 1) * limit
    };
  }
}

export { AjaxModel };
