import common from '../common';

class SearchStore {

    data: any[];
    result: object[];
    paras: any;

    constructor(data: any[] = []) {
        this.data = data;
        this.result = [];
        this.paras = {}
    }


    /**
     * 重新加载数据
     * @param {array} data
     */
    setData(data: any[]): void {
        this.data = data;
        this.query();
    }

    /**
     * 设置查询参数
     * @param {string} key - 参数名称
     * @param {string|number|boolean|function|array} value - 参数值
     * 根据参数值的类型不同，执行不同的过滤行为
     * string：模糊查找
     * number、boolean：精确匹配
     * function：filter
     * array：匹配array中任意一项
     */
    setPara(key: string, value: any): void {
        this.paras[key] = value;
        this.query();
    }

    /**
     * 移除指定查询参数
     * @param {string}key
     */
    delPara(key: string): void {
        delete this.paras[key];
        this.query();
    }

    /**
     * 移除所有查询参数
     */
    clearPara(): void {
        this.paras = {};
        this.result = this.data;
    }

    /**
     * 执行搜索
     * 该方法不应由外部调用
     */
    query(): void {
        const keys = Object.keys(this.paras);
        let result = this.data;
        keys.forEach((key) => {
            const value = this.paras[key];
            switch (common.getType(value)) {
                case 'String':
                    if (value) {
                        result = result.filter(function (item: any) {
                            return item[key].indexOf(value) > -1;
                        });
                    }
                    break;
                case 'Function':
                    result = result.filter(value);
                    break;
                case 'Array':
                    result = result.filter(function (item: any) {
                        return value.indexOf(item[key]) > -1;
                    });
                    break;
                case 'Number':
                case 'Boolean':
                    result = result.filter(function (item: any) {
                        return item[key] === value;
                    });
                    break;
            }
        });
        this.result = result;
        this.onChange(result);
    }

    onChange(result: object[]) {

    }
}

export default SearchStore;
