import { checkModel } from "../validate/checkModel";
import { common } from "../common";
import { Ajax } from "@/utils/ajax";
import { AppConfig } from "@/app-config";
import { PageStore } from "@/utils/dataModel/pageStore";

class BaseModel {
  /**
   * 标识列
   */
  IdKey: string;
  /**
   * 默认值
   */
  Props: object;
  /**
   * 添加或修改数据时 提交数据的方式
   * 可选post、postJson、get
   */
  SetMode: "get" | "post" | "postJson";
  /**
   * 删除数据时 提交数据的方式
   * 可选post、postJson、get
   */
  DelMode: "get" | "post" | "postJson";

  /**
   * 标识
   */
  id?: number | string;

  /**
   * 提交数据前 对数据进行校验的规则
   */
  Rule: any;

  /**
   * $props中包含的所有键名
   */
  Keys: string[];

  /**
   * 分页数据集
   */
  Store: PageStore;

  List: any[];

  constructor() {
    this.IdKey = "id";
    this.id = 0;

    this.Props = {};
    this.SetMode = "postJson";
    this.DelMode = "get";
    this.Rule = {};
    this.Keys = [];
    this.List = [];
    this.Store = new PageStore('');
  }

  /**
   * 初始化类 将$props中所有属性名称存入$keys，方便调用
   */
  Init(): void {}

  CheckData() {
    return checkModel(this, this.Rule);
  }

  /**
   * 将所有属性重置为默认值
   */
  Restore(): void {
    Object.assign(this, this.Props);
  }

  /**
   * 生成添加或修改时 需要发送到后端的数据
   */
  CreateFormData(): object {
    return common.assign({}, this, this.Keys);
  }

  /**
   * 获取单条数据
   * @constructor
   */
  GetData(id = "") {}

  /**
   * 实例初始化时调用
   */
  vInit() {}

  /**
   * $getData调用成功后触发
   * 用于对后端输出的数据作额外处理
   * @param res
   */
  vAfterGet(res: any): any {
    return res;
  }

  vAfterGetList(data: any): object[] {
    return data;
  }
}

export { BaseModel };

export default BaseModel;
