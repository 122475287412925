import { AppConfig } from "@/app-config";
import { Ajax } from "@/utils/ajax";

interface ListResult {
  /**
   * 总记录数
   */
  total: number;
  /**
   * 数据集
   */
  data: any[];
}

class PageStore {
  /**
   * 调用分页数据的模式
   * get post
   */
  AjaxType: string;

  /**
   * 接口地址
   */
  Api: string;

  /**
   * list请求的action
   */
  ListAction?: string;
  /**
   * 当前分页的数据
   */
  Data: object[];
  /**
   * 每页加载数据行数
   */
  Limit: number;
  /**
   * 当前页面
   */
  Page: number;
  /**
   * 当前是否正在加载中
   */
  IsLoading: boolean;
  /**
   * 是否已到末页
   */
  IsEnd: boolean;
  /**
   * 总记录数
   */
  Total: number;
  /**
   * 总页数
   */
  TotalPage: number;
  /**
   * 搜索表单model
   */
  SearchForm: any;
  /**
   * 已应用的查询参数model
   */
  SearchPara: any;
  /**
   * 标识列列名
   */
  IdKey: string;

  constructor(api: string = "", action: string = "") {
    this.Api = api;
    if (AppConfig.apiMode === "action") {
      this.ListAction = action;
    }
    this.AjaxType = "get";
    this.Data = [];
    this.Limit = 20;
    this.Page = 1;
    this.IsLoading = false;
    this.IsEnd = false;
    this.Total = 0;
    this.TotalPage = 0;
    this.SearchForm = {};
    this.SearchPara = {};
    this.IdKey = "id";
  }

  /**
   * 加载分页数据
   * @param opt - 其他参数
   */
  GetPageData(opt = {}) {
    const paras: any = {
      limit: this.Limit,
      offset: this.Limit * (this.Page - 1)
    };
    if (AppConfig.apiMode === "action") {
      paras.action = this.ListAction;
    }
    Object.assign(paras, this.SearchPara, opt);
    this.IsLoading = true;
    return Ajax.post(this.Api, paras)
      .then((res: any) => {
        this.IsLoading = false;
        const result: ListResult = this.vAfterGetList(res);
        this.TotalPage = Math.ceil(result.total / this.Limit);
        this.Total = result.total;
        this.Data = result.data;
        return result.data;
      })
      .catch(err => {
        this.IsLoading = false;
        return err;
      });
  }

  /**
   * 在当前页数据中通过idKey查找匹配的数据
   * @param id
   */
  Find(id: number | string) {
    return this.Data.find((item: any) => {
      return item[this.IdKey] === id;
    });
  }

  /**
   * 应用查询参数
   */
  Search() {
    this.Page = 1;
    this.IsEnd = false;
    Object.assign(this.SearchPara, this.SearchForm);
    const keys = Object.keys(this.SearchPara);
    //自动去除空值
    keys.forEach(key => {
      if (this.SearchPara[key] === "") {
       delete this.SearchPara[key];
      }
    });
    this.vBeforeSearch(this.SearchPara, this.SearchForm);
    return this.GetPageData();
  }

  Clear() {
    this.Page = 1;
    this.IsEnd = false;
    this.Total = 0;
    this.TotalPage = 0;
    this.Data = [];
  }

  /**
   * 重置limit
   * @param limit
   */
  SetLimit(limit: number) {
    this.Limit = limit;
    this.Page = 1;
    this.IsEnd = false;
    this.GetPageData();
  }

  /**
   * $getPage（分页list）加载完成后触发
   * 用于对后端输出的数据作额外处理
   * @param res
   */
  vAfterGetList(res: any): ListResult {
    return {
      total: res.total,
      data: res.data
    };
  }

  /**
     *
     /**
     * 事件 当用户点击搜索（应用搜索条件）时触发
     * 事件内可对$searchPara中的数据进行转换
     * @param result = this.$searchPara
     * @param source = this.$searchForm
     */
  vBeforeSearch(result: object, source: object) {}
}

export { ListResult, PageStore };
