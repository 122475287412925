
import {defineComponent, ref} from "vue";
import {Auth} from "@/auth/login";

export default defineComponent({
  name: "login-form",
  setup(props, {emit}) {
    const userName = ref('');
    const password = ref('');
    const errText = ref('');

    function login() {
      if (userName.value.length === 0 || password.value.length === 0) {
        return;
      }
      Auth.checkPass(userName.value, password.value).then((res) => {
        Auth.saveToken(res.token);
        emit('login');
      }).catch((errMsg) => {
        errText.value = errMsg;
      })
    }

    function clearErr() {
      errText.value = '';
    }

    return {
      userName,
      password,
      login,
      errText,
      clearErr
    }
  },
  methods: {}
})
