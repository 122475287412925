import {cacheFront} from "@/config/cacheFront";

class CacheModel {

    /**
     * 前缀 防止同一域名下 不同项目的命名冲突
     */
    front: string;

    constructor() {
        this.front = cacheFront;
    }

    /**
     * 获取缓存
     * @param key - 键名
     * @param useFront - 是否启用前缀
     */
    get(key: string, useFront = true): string | null {
        const name = useFront ? `${this.front}${key}` : key;
        return localStorage.getItem(name);
    }

    /**
     * 保存字符型缓存
     * @param key - 键名
     * @param value - 键值
     * @param useFront - 是否启用前缀
     */
    set(key: string, value: string, useFront = true): void {
        const name = useFront ? `${this.front}${key}` : key;
        localStorage.setItem(name, value);

    }

    /**
     * 保存object型缓存
     * @param key - 键名
     * @param value - 键值
     * @param useFront - 是否启用前缀
     */
    setJson(key: string, value: Object, useFront = true): void {
        const name = useFront ? `${this.front}${key}` : key;
        localStorage.setItem(name, JSON.stringify(value));
    }

    /**
     * 读取object型缓存
     * @param key - 键名
     * @param useFront - 是否启用前缀
     */
    getJson(key: string, useFront = true): object | null {
        const name = useFront ? `${this.front}${key}` : key;
        if (this.has(name, false)) {
            return JSON.parse(this.get(name, false) || '{}')
        } else {
            return null;
        }
    }

    /**
     * 判断缓存是否存在
     * @param key - 键名
     * @param useFront - 是否启用前缀
     */
    has(key: string, useFront = true): boolean {
        const name = useFront ? `${this.front}${key}` : key;
        return localStorage.getItem(name) !== null;
    }

    /**
     * 移除缓存
     * @param key
     * @param useFront
     */
    remove(key: string, useFront = true): void {
        const name = useFront ? `${this.front}${key}` : key;
        localStorage.removeItem(name);

    }
}

const Cache = new CacheModel();

export default Cache;

export {
    Cache,
    CacheModel
}
