import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import index from '../views/index.vue'
import convert from '../views/convert.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: '',
    component: convert
  },
  {
    path: '/convert',
    name: 'convert',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/convert.vue')
  },
  {
    path: '/pdd',
    name: 'pdd',
    component: () => import(/* webpackChunkName: "pdd" */ '../views/pdd.vue')
  },
  {
    path: '/system/project',
    name: 'project',
    component: () => import(/* webpackChunkName: "system" */ '../views/system/project.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
