import {Ajax, Cache} from "@/utils";
import AppConfig from "@/app-config";

const Auth = {
    checkToken(token: string) {
        return Ajax.post(AppConfig.kxApi, {
            action: 'admin_token_check',
            token
        })
    },
    checkPass(userName: string, userPass: string) {
        return Ajax.post(AppConfig.kxApi, {
            action: 'admin_login',
            userName,
            userPass,
            cookiesDay: 365
        })

    },
    saveToken(token: string) {
        Ajax.header.token = token;
        Cache.set('token', token);
    },
    removeToken() {
        Cache.remove('token');
    }
}

export {
    Auth
}