let serverPath = "//kx.1314179.com.cn";
if (process.env.NODE_ENV === "development") {
    serverPath = "http://192.168.3.99";
}

const AppConfig = {
    apiUrl(url: string): string {
        return serverPath + url;
    },
    debug: false,
    kxApi: serverPath + "/api/kaixin.ashx",
    xyApi: serverPath + "/api/xianyu.ashx",
    openApi: serverPath + "/api/open.ashx",
    loginApi: serverPath + "/api/adminLogin.ashx",
    uploadApi: serverPath + "/service/admin.ashx?action=upload_img",
    SITE_CODE: "kaixin",
    recoveryApi: serverPath + "/api/recoveryV2.ashx",
    imgServer: "//kx.1314179.com.cn",
    photoServer: "//kx.1314179.com.cn",
    imgFileType: "image/png,image/jpg,image/jpeg,image/gif",
    apiMode: "action",

    myAddress: '湖南省长沙市雨花区洞井街道轻奢时代10栋102芙蓉兴盛超市（7-2501）',
};


export {AppConfig};

export default AppConfig;
