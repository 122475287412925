let $g: any = {};
let id = 10000;
const Global = {
    /**
     * 获取全局变量
     * @param {string} name
     */
    get(name: string): any {
        return $g[name];
    },
    /**
     * 设置全局变量
     * @param {String}name
     * @param value
     */
    set(name: string, value: any): void {
        $g[name] = value;
    },
    /**
     * 移除全局变量
     * @param {string} name
     */
    remove(name: string): void {
        delete $g[name];
    },

    newId(): number {
        id++;
        return id;
    },
};

export {
    Global
}

export default Global;
