import {createApp} from 'vue'
import Antd from 'ant-design-vue';
import App from './App.vue'
import router from './router'
import 'ant-design-vue/dist/reset.css';
import {common, Cache} from "@/utils";
import {Auth} from "@/auth/login";

let token = getToken();
if (token) {
    Auth.checkToken(token).then(() => {
        Auth.saveToken(token);
        render();
    }).catch(() => {
        Auth.removeToken();
        render();
    })
} else {
    render();
}


//region getToken
/*
获取token
 */
function getToken(): string {
    let token = '';
    const paras = common.getPara();
    if (paras.token) {
        token = paras.token;
        window.history.pushState(null, '', window.location.origin + '/');
    } else {
        const cacheToken = Cache.get('token');
        if (cacheToken) {
            token = cacheToken;
        }
    }
    return token;
}

//endregion

function render() {
    createApp(App).use(Antd).use(router).mount('#app')
}