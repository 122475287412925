
import {defineComponent, ref} from "vue";
import {kDate, Ajax, layer, arrTool, common} from "@/utils";
import {App} from 'ant-design-vue';

interface Price {
  name: string;
  point: number;
}

export default defineComponent({
  setup() {

    common.setTitle('智能报价转换');
    const {message, modal, notification} = App.useApp();

    const sourceContent = ref('');
    const tplContent = ref('');
    const content = ref('');
    const point = ref(1);
    const priceList = ref([] as Price[]);


    /**
     * 转换带小数点的
     */
    function convert() {
      tplContent.value = sourceContent.value.replace(/(([0-9]+|0)\.([0-9]{1,2}))/g, function (a, b, c, d, e, f) {
        if (parseFloat(a) < 100) {
          return `<span>${a}</span>`
        } else {
          return a;
        }
      });

      createPrice();
    }

    /**
     * 转换带符号-的
     */
    function convert2() {
      tplContent.value = sourceContent.value.replace(/-(([0-9]+|0)(\.?[0-9]?))/g, function (a, b, c, d, e, f) {
        //console.log(a, b, c, d, e)
        if (parseFloat(a) < 100) {
          return `-<span>${b}</span>`
        } else {
          return a;
        }
      });

      createPrice();
    }

    /*
    如果小数点后面是0或者5，视为点位
     */
    function isPoint(value: string) {
      const point = value.split('.')[1] || '';
      return point === '' || ['0', '5'].includes(point);
    }


    function convert3() {
      let temp = tplContent.value = sourceContent.value.replace(/点(([0-9]+|0)(\.?[0-9]?))/g, function (a, b, c, d, e, f) {
        return `点<span>${b}</span>`
      });
      if (temp.length === 0) {
        message.error('请输入上家报价');
        return;
      }
      temp = temp.replace(/(([0-9]+|0)\.([0-9]{1,2}))/g, function (a, b, c, d, e, f) {
        if (parseFloat(a) < 100 && isPoint(a)) {
          return `<span>${a}</span>`
        } else {
          return a;
        }
      });
      temp = temp.replace(/点位([0-9]{1,2})/g, function (a, b, c, d, e, f) {
        console.log('a', a)
        console.log('b', b)
        return `点位<span>${b}</span>`
      });
      tplContent.value = temp.replace(/-(([0-9]+|0)(\.?[0-9]?))/g, function (a, b, c, d, e, f) {
        console.log(a, b, c, d, e)
        if (parseFloat(b) < 100) {
          return `-<span>${b}</span>`
        } else {
          return a;
        }
      });

      createPrice();
      message.success('报价已生成');
    }

    function createPrice() {

      content.value = tplContent.value.replace(/\<span>(.*?)\<\/span>/g, function (a, b, c, d, e, f) {
        //console.log({a, b, c, d, e, f});
        return `${common.addPoint(b, point.value)}`;
      });
      const list = [] as Price[];
      content.value.split('\n').forEach(function (lineStr: string) {
        lineStr = lineStr.replace('➦', '-');
        if (lineStr.includes('-')) {
          const values = lineStr.split('-');
          list.push({
            name: values[0],
            point: parseFloat(values[1])
          });
        }
      });
      priceList.value = arrTool.ascOrder(list,'point');
    }


    return {
      convert,
      convert2,
      convert3,
      sourceContent,
      tplContent,
      content,
      point,
      priceList
    }
  },
  methods: {}
})
