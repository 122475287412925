/**
 * 将对象数组中的某个属性重组为一个新的数组
 * @param {Array} arr 数组
 * @param {String} key
 * @param {Boolean} unique - 是否去重
 * @returns {Array}
 */
export function selectProp(
  arr: object[],
  key: string = "",
  unique = false
): string[] {
  if (key.length === 0) {
    return [];
  }
  if (!unique) {
    const result: any[] = [];
    arr.forEach(function(item: any) {
      result.push(item[key]);
    });
    return result;
  } else {
    return selectPropUnique(arr, key);
  }
}

/**
 * 将对象数组中的某个属性重组为一个新的数组 并自动去重
 * @param {Array} arr 数组
 * @param {String} key
 * @returns {Array}
 */
export function selectPropUnique(arr: object[], key: string = ""): any[] {
  return Array.from(new Set(selectProp(arr, key)));
}

/**
 * 将对象数组中的某一列累加
 * @param {Array} arr 数组
 * @param {String} key - 属性名。如果不指定，则视为累加整个数组
 * @param {Number} bei - 计算精度
 * @returns {Number}
 */
export function selectSum(arr: object[] | number[], key: string = "", bei = 1):number {
  let total = 0;
  if (key.length > 0) {
    arr.forEach(function(item: any) {
      total += item[key] * bei;
    });
  } else {
    arr.forEach(function(item: any) {
      total += item * bei;
    });
  }
  return (total / bei) as number;
}

/**
 * 获取数组中最小的元素
 * @param arr
 * @returns {*}
 */
export function selectMin(arr: number[]) {
  let result = arr[0];
  arr.forEach(item => {
    if (item < result) result = item;
  });
  return result;
}

/**
 * 获取数组中最大的元素
 * @param arr
 * @returns {*}
 */
export function selectMax(arr: number[]) {
  let result = arr[0];
  arr.forEach(item => {
    if (item > result) result = item;
  });
  return result;
}
