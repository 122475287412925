import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { id: "app-menu" }
const _hoisted_3 = { id: "app-main" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_menu = _resolveComponent("a-menu")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_login_form = _resolveComponent("login-form")!
  const _component_a_app = _resolveComponent("a-app")!

  return (_openBlock(), _createBlock(_component_a_app, null, {
    default: _withCtx(() => [
      (_ctx.isLogin)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_a_menu, {
                selectedKeys: _ctx.current,
                "onUpdate:selectedKeys": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.current) = $event)),
                mode: "horizontal",
                items: _ctx.items,
                theme: "dark",
                onClick: _ctx.handleClick
              }, null, 8, ["selectedKeys", "items", "onClick"])
            ]),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_router_view)
            ])
          ]))
        : (_openBlock(), _createBlock(_component_login_form, {
            key: 1,
            onLogin: _ctx.onLoginSuccess
          }, null, 8, ["onLogin"]))
    ]),
    _: 1
  }))
}