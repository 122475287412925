import {
    ascOrder,
    descOrder,
} from './sort'

import {
    selectProp,
    selectPropUnique,
    selectSum,
    selectMin,
    selectMax,
} from './select'

import {shuffle} from './shuffle'

export default {
    ascOrder,
    descOrder,
    selectProp,
    selectPropUnique,
    selectSum,
    selectMin,
    selectMax,
    shuffle,
}
