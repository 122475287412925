
import {defineComponent, ref, h} from "vue";
import {layer, common, Ajax} from "@/utils";
import LoginForm from "@/components/login-form.vue";
import {SyncOutlined} from '@ant-design/icons-vue';
import {MenuProps} from 'ant-design-vue';
import router from "@/router";
import {MenuPath} from "@/config/menuPath";

export default defineComponent({
  name: "App",
  components: {
    LoginForm,
  },

  setup() {
    const isLogin = ref(!!Ajax.header.token);
    if (!isLogin) {
      common.setTitle('管理员登录');
    }

    function onLoginSuccess() {
      isLogin.value = true;
    }

    const current = ref<string[]>(['convert']);
    const items = ref<MenuProps['items']>([
      {
        key: 'convert',
        icon: () => h(SyncOutlined),
        label: '智能报价转换',
        title: '将其他人的报价智能增加点位'
      }, {
        key: 'pdd',
        icon: () => h(SyncOutlined),
        label: '拼多多',
        title: '拼多多先用后付商品管理'
      }, {
        key: 'system',
        icon: () => h(SyncOutlined),
        label: '系统设置',
        children: [
          {
            label: '项目管理',
            key: 'project',
          }
        ],
      },
    ]);

    function handleClick(info: { key: string }) {
      if (!info.key) {
        return;
      }
      if(MenuPath.hasOwnProperty(info.key)){
        // @ts-ignore
        router.push(MenuPath[info.key]);
      }
    }

    return {
      isLogin,
      onLoginSuccess,
      current,
      items,
      handleClick
    }
  }
});
